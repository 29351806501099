import '../styles/globals.css'
import { DefaultSeo } from 'next-seo'
import Script from 'next/script'

function MyApp({ Component, pageProps }) {
    return (
        <>
            <Script id="facebook-pixel">
                {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
            fbq('track', 'PageView');
          `}
            </Script>
            <DefaultSeo
                title="Passagens Aéreas - Descontos HOJE!"
                description="Chega de ficar procurando em qualquer lugar. ✈️ As melhores passagens aéreas baratas você encontra na Fly Prime. Aproveite nossas ofertas e promoções!"
                canonical="https://flyprime.com.br/"
                openGraph={{
                    type: 'website',
                    locale: 'pt_BR',
                    url: 'https://www.flyprime.com.br/',
                    siteName: 'FlyPrime Viagens',
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Component {...pageProps} />
        </>
    )
}

export default MyApp
